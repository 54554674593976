var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('dragablemodal',{ref:"dragablemodal",attrs:{"name":'postingeditmodal',"height":'auto',"width":900}},[_c('header',[_vm._v(" "+_vm._s(_vm.T( "Web.Generic.Invoices.Reconciliations.PostingEditPopup.Title", "Provide information" ))+" ")]),_c('div',{staticClass:"modals-content"},[(_vm.posting != null)?_c('b-form',{on:{"submit":function($event){$event.stopPropagation();$event.preventDefault();return _vm.handleSubmit.apply(null, arguments)}}},[_c('b-row',[_c('b-col',{attrs:{"cols":"4"}},[_c('b-form-group',{attrs:{"label":_vm.T('Web.Generic.Postings.PostingType', 'Posting type'),"label-for":"type-input"}},[_c('b-form-select',{attrs:{"id":"type-input","options":_vm.postingTypeOptions},model:{value:(_vm.posting.postingCategory),callback:function ($$v) {_vm.$set(_vm.posting, "postingCategory", $$v)},expression:"posting.postingCategory"}}),(!_vm.valueIsNotEmptyOrUnknown(_vm.posting.postingCategory))?_c('small',{staticClass:"missing-text"},[_vm._v(_vm._s(_vm.T( "Web.Generic.Invoices.Reconciliations.PostingEditPopup.MissingType", "Provide type" )))]):_vm._e()],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"4"}},[_c('b-form-group',{attrs:{"label":_vm.T('Web.Generic.Date'),"label-for":"date-input"}},[_c('b-form-datepicker',{attrs:{"id":"date-input","autofocus":"","trim":"","date-format-options":{
                year: 'numeric',
                month: 'short',
                day: '2-digit',
              }},model:{value:(_vm.posting.postingDate),callback:function ($$v) {_vm.$set(_vm.posting, "postingDate", $$v)},expression:"posting.postingDate"}}),(!_vm.valueIsNotEmptyOrUnknown(_vm.posting.postingDate))?_c('small',{staticClass:"missing-text"},[_vm._v(_vm._s(_vm.T( "Web.Generic.Invoices.Reconciliations.PostingEditPopup.MissingDate", "Provide date" )))]):_vm._e()],1)],1),_c('b-col',{attrs:{"cols":"8"}},[_c('b-form-group',{attrs:{"label":_vm.T('Web.Generic.Description'),"label-for":"description-input"}},[_c('b-form-input',{attrs:{"id":"description-input","trim":"","autofocus":""},model:{value:(_vm.posting.postingDescription),callback:function ($$v) {_vm.$set(_vm.posting, "postingDescription", $$v)},expression:"posting.postingDescription"}})],1)],1),_c('b-col',{attrs:{"cols":"2"}},[_c('b-form-group',{attrs:{"label":_vm.T('Web.Generic.Invoices.LineAmount'),"label-for":"quantity-input"}},[_c('b-form-input',{attrs:{"id":"quantity-input","trim":"","lazy":"","type":"number"},on:{"change":function($event){_vm.posting.postingQuantity = _vm.formatCurrency(
                  _vm.posting.postingQuantity
                )}},model:{value:(_vm.posting.postingQuantity),callback:function ($$v) {_vm.$set(_vm.posting, "postingQuantity", $$v)},expression:"posting.postingQuantity"}})],1)],1),_c('b-col',{attrs:{"cols":"2"}},[_c('b-form-group',{attrs:{"label":_vm.T('Web.Generic.Invoices.LineUnit'),"label-for":"unit-input"}},[_c('b-form-select',{attrs:{"id":"unit-input","options":_vm.unitTypeOptions},model:{value:(_vm.posting.postingUnit),callback:function ($$v) {_vm.$set(_vm.posting, "postingUnit", $$v)},expression:"posting.postingUnit"}}),(!_vm.valueIsNotEmptyOrUnknown(_vm.posting.postingUnit))?_c('small',{staticClass:"missing-text"},[_vm._v(_vm._s(_vm.T( "Web.Generic.Invoices.Reconciliations.PostingEditPopup.MissingUnit", "Provide unite" )))]):_vm._e()],1)],1),_c('b-col',{attrs:{"cols":"4"}},[_c('b-form-group',{attrs:{"label":_vm.T('Web.Generic.Invoices.LineUnitPrice'),"label-for":"amount-input"}},[_c('b-form-input',{attrs:{"id":"amount-input","trim":"","type":"number","lazy":""},on:{"change":function($event){_vm.posting.postingUnitPrice = _vm.formatCurrency(
                  _vm.posting.postingUnitPrice
                )}},model:{value:(_vm.posting.postingUnitPrice),callback:function ($$v) {_vm.$set(_vm.posting, "postingUnitPrice", $$v)},expression:"posting.postingUnitPrice"}})],1)],1),_c('b-col',{attrs:{"cols":"4"}},[_c('b-form-group',{attrs:{"label":_vm.T('Web.Generic.Invoices.LineTotal'),"label-for":"amount-input"}},[_c('b-form-input',{attrs:{"id":"amount-input","trim":"","type":"number","lazy":""},on:{"change":function($event){_vm.posting.postingTotalPrice = _vm.formatCurrency(
                  _vm.posting.postingTotalPrice
                )}},model:{value:(_vm.posting.postingTotalPrice),callback:function ($$v) {_vm.$set(_vm.posting, "postingTotalPrice", $$v)},expression:"posting.postingTotalPrice"}})],1)],1)],1),(!_vm.shouldShowSecondaryQuantityFields)?_c('div',[_c('b-row',{staticClass:"mb-1 mt-1"},[_c('b-col',{attrs:{"cols":"12"}},[_c('a',{staticClass:"font-weight-bold",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();_vm.secondaryQuantityFieldsVisible =
                  !_vm.secondaryQuantityFieldsVisible}}},[_vm._v(_vm._s(_vm.secondaryQuantityFieldsVisible ? _vm.T( "Web.Generic.Invoices.Reconciliations.PostingEditPopup.CloseSecondaryField", "Close Secondary field" ) : _vm.T( "Web.Generic.Invoices.Reconciliations.PostingEditPopup.OpenSecondaryField", "Open Secondary field" ))),(!_vm.secondaryQuantityFieldsVisible)?_c('feather-icon',{attrs:{"icon":"ChevronDownIcon"}}):_c('feather-icon',{attrs:{"icon":"ChevronUpIcon"}})],1)])],1),(_vm.secondaryQuantityFieldsVisible)?_c('b-row',[_c('b-col',{attrs:{"cols":"4"}},[_c('b-form-group',{attrs:{"label":_vm.T('Web.Generic.Invoices.LineAmount'),"label-for":"quantity-input"}},[_c('b-form-input',{attrs:{"id":"quantity-input","trim":"","type":"number","lazy":""},on:{"change":function($event){_vm.posting.postingSecondaryQuantity = _vm.formatCurrency(
                    _vm.posting.postingSecondaryQuantity
                  )}},model:{value:(_vm.posting.postingSecondaryQuantity),callback:function ($$v) {_vm.$set(_vm.posting, "postingSecondaryQuantity", $$v)},expression:"posting.postingSecondaryQuantity"}})],1)],1),_c('b-col',{attrs:{"cols":"2"}},[_c('b-form-group',{attrs:{"label":_vm.T('Web.Generic.Invoices.LineUnit'),"label-for":"unit-input"}},[_c('b-form-select',{attrs:{"id":"unit-input","options":_vm.secondaryUnitTypeOptions},model:{value:(_vm.posting.postingSecondaryUnit),callback:function ($$v) {_vm.$set(_vm.posting, "postingSecondaryUnit", $$v)},expression:"posting.postingSecondaryUnit"}})],1)],1)],1):_vm._e()],1):_vm._e(),(_vm.shouldShowSecondaryQuantityFields)?_c('b-row',[_c('b-col',{attrs:{"cols":"4"}},[_c('b-form-group',{attrs:{"label":_vm.T('Web.Generic.Invoices.LineAmount'),"label-for":"quantity-input"}},[_c('b-form-input',{attrs:{"id":"quantity-input","trim":"","type":"number"},model:{value:(_vm.posting.postingSecondaryQuantity),callback:function ($$v) {_vm.$set(_vm.posting, "postingSecondaryQuantity", $$v)},expression:"posting.postingSecondaryQuantity"}}),(
                !_vm.valueIsNotEmptyOrUnknown(_vm.posting.postingSecondaryQuantity)
              )?_c('small',{staticClass:"missing-text"},[_vm._v(_vm._s(_vm.T( "Web.Generic.Invoices.Reconciliations.PostingEditPopup.CollectionQuantityMissing", "Provide collection quantity" )))]):_vm._e()],1)],1),_c('b-col',{attrs:{"cols":"2"}},[_c('b-form-group',{attrs:{"label":_vm.T('Web.Generic.Invoices.LineUnit'),"label-for":"unit-input"}},[_c('b-form-select',{attrs:{"id":"unit-input","options":_vm.unitTypeOptions},model:{value:(_vm.posting.postingSecondaryUnit),callback:function ($$v) {_vm.$set(_vm.posting, "postingSecondaryUnit", $$v)},expression:"posting.postingSecondaryUnit"}})],1)],1)],1):_vm._e()],1):_vm._e()],1),_c('footer',{staticClass:"modal-footer"},[_c('div',{staticClass:"w-100"},[_c('b-button',{staticClass:"float-right",attrs:{"variant":"primary","disabled":!_vm.canSubmit},on:{"click":_vm.handleSubmit}},[_vm._v(" "+_vm._s(_vm.T("Web.Generic.Approve"))+" ")]),_c('b-button',{staticClass:"float-right mr-2",attrs:{"variant":"light"},on:{"click":_vm.handleCancel}},[_vm._v(" "+_vm._s(_vm.T("Web.Generic.Cancel"))+" ")])],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }