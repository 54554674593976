<template>
  <div class="invoice-wrapper">
    <div v-if="transformedData" v-html="transformedData"></div>
  </div>
</template>

<script>
import axios from "axios";
import ResourceService from "@/services/base/resource.service";

export default {
  setup() {},
  data() {
    return {
      transformedData: null,
      terms: {
        Release: "OIOUBL visning version 2.0.0 (2017)",
        AcceptedIndicator: "Respons",
        AcceptedIndicatorFalse: "Afvist",
        AcceptedIndicatorTrue: "Accepteret",
        AccountingCost: "Dimensions konto:",
        AccountingCostCode: "Dimensions kontokode",
        AccountingCustomerInv: "Fakturamodtager",
        AccountingCustomerCrn: "Kreditnotamodtager",
        AccountingCustomerOrd: "Fakturamodtager",
        AccountingSupplierInv: "Fakturaafsender",
        AccountingSupplierCrn: "Kreditnotaafsender",
        AccountingSupplierOrd: "Fakturaudsteder",
        ActualDeliveryDate: "Leveringsdag:",
        ActualDeliveryTime: "Leveringstid",
        AdditionalDocumentReferenceID: "Yderligere reference:",
        AdditionalInformation: "Supplerende information:",
        AdditionalItemIdentification: "Yderligere varenr:",
        AdditionalItemIdentificationExt: "Yderligere udvidetvarenr:",
        AdditionalItemProperty: "Yderligere varebeskrivelse:",
        AddressAdditionalStreet: "Vej adresseringsnavn:",
        AddressID: "Adresse ID:",
        AddressBuildingName: "Lokalitet:",
        AddressCountry: "Land:",
        AddressCountryCode: "Landekode:",
        AddressDistrict: "Distrikt:",
        AddressDepartment: "Afdeling:",
        AddressFloor: "Etage:",
        AddressRegion: "Region:",
        AddressRoom: "Rum:",
        AllowanceChargePrice: "Pris incl",
        AllowanceChargeSupp: "Supplerende oplysninger til Gebyr/Rabat",
        AllowanceTotalAmount: "Rabat total:",
        Amount: "Beløb:",
        AnticipatedMonetaryTotalOrd: "Forventet ordretotal incl moms",
        ApplicationResponseID: "Respons ID:",
        BackOrderAllowedIndicatorTrue: "Restordre tilladt",
        BaseAmount: "Basis beløb:",
        BaseUnitMeasure: "Basis enhed:",
        BillingReferenceLine: "Linjereference:",
        BrandName: "Mærke:",
        BuyerParty: "Køber",
        BuyersItemIdentification: "Købers varenr:",
        BuyersItemIdentificationExt: "Købers udvidetvarenr:",
        CalculationRate: "Beregningskurs:",
        CancellationNote: "Annulleringsårsag",
        CatalogueID: "KatalogID:",
        CatalogueIndicatorTrue: "Katalogvare",
        CatalogueItemIdentification: "Katalog varenr:",
        CatalogueItemIdentificationExt: "Katalog udvidetvarenr:",
        CatalogueIssueDate: "UdstedelsesDato:",
        CertificateID: "Certificate ID:",
        CertificateTypeCode: "Certificate Type Code:",
        CertificateType: "Certificate Type:",
        ChargeIndicatorTrue: "Gebyr",
        ChargeIndicatorFalse: "Rabat",
        ChargeTotalAmount: "Gebyr total:",
        ClassifiedTaxCategory: "Klassificeret afgiftskategori:",
        CommodityCode: "Varetypekode:",
        Contact: "Kontaktoplysninger",
        ContactID: "Personreference:",
        ContentUnitQuantity: "Salgbar kvantum",
        ContractID: "Kontrakt ID:",
        ContractIssueDate: "Kontraktdato:",
        ContractDocumentReference: "Kontraktreference:",
        ContractDocumentReferenceID: "Kontrakt:",
        ContractType: "Kontrakttype:",
        ContractTypeCode: "Kontrakttypekode:",
        CopyIndicator: "(Kopi)",
        CopyIndicatorTrue: "Kopiindikator: Kopi",
        CopyIndicatorFalse: "Kopiindikator: Original",
        CreditNoteDocumentReference: "Kreditnotareference:",
        CreditNoteID: "Nummer:",
        CurrencyCode: "Valutakode:",
        CustomizationID: "CustomizationID:",
        Delivery: "Leveringsoplysninger",
        DeliveryAddress: "Leveringsadresse",
        DeliveryLocation: "Leveringssted:",
        DeliveryLocationHeader: "Leveringssted",
        DeliveryLocationAddress: "Adresse:",
        DeliveryLocationID: "ID:",
        DeliveryLocationDescription: "Beskrivelse:",
        DeliveryLocationConditions: "Betingelser:",
        DeliveryTerms: "Leveringsbeting.:",
        DeliveryTermsHeader: "Leveringsbetingelser",
        DeliveryTermsID: "Leverings ID:",
        DeliverySpecialTerms: "Specielle leveringsbeting:",
        DeliveryTime: "Levering",
        DeliveryParty: "Leveringspart:",
        DeliveryPartyHeader: "Leveringspart",
        Description: "Beskrivelse:",
        DocumentCurrencyCode: "Dokument valuta:",
        DocumentHash: "Hash værdi:",
        DocumentReference: "Dokumentreference",
        DocumentReferenceID: "Dokument ID",
        DocumentType: "Dokumenttype:",
        DocumentTypeCode: "Dokumenttype kode:",
        ElectronicMail: "E-mail:",
        EmbeddedDocumentBinaryObject: "Indlejret dokument:",
        EndpointID: "EndepunktID",
        EndpointSender: "Afsender EndepunktD:",
        EndpointReceiver: "Modtager EndepunktD:",
        ExchangeMarketID: "Valutamarked:",
        ExpiryDate: "Udløbsdato:",
        ExpiryTime: "Udløbstid:",
        ExtendedDescription: "Lang beskrivelse:",
        FinancialInstitutionID: "SWIFT:",
        FinancialInstitutionName: "Bank:",
        FreeOfChargeIndicatorTrue: "Varen er gratis",
        ID: "ID:",
        ItemClassificationCode: "Vareklassificering:",
        ItemDescription: "Varebeskrivelse:",
        InvoiceDocumentReference: "Fakturareference:",
        InvoiceID: "Fakturanr:",
        InvoiceIDShort: "Nummer:",
        InvoiceIssueDate: "Fakturadato:",
        InvoicePeriod: "Fakturaperiode:",
        InspectionMethodCode: "Inspektionsmetodekode:",
        InstructionNote: "Lang advisering:",
        IssueDate: "Dato:",
        ItemName: "Beskrivelse",
        JurisdictionRegionAddress: "Myndighedsområde:",
        LatestDeliveryDate: "Senest leveringsdato:",
        LatestDeliveryTime: "Senest leveringstidspunkt:",
        LeadTimeMeasure: "Leveringstid:",
        LegalMonetaryTotal: "Supplerende oplysninger om totaler",
        LineExtensionAmountTotal: "Linjesum i alt excl moms",
        LineExtensionAmountLine: "Pris",
        LineID: "Linje",
        LineResponse: "Supplerende linjeoplysninger",
        LineReference: "Linjereference",
        LotNumberID: "Lotidentifikation:",
        ManufacturersItemIdentification: "Producentens varenr:",
        ManufacturersItemIdentificationExt: "Producentens udvidetvarenr:",
        MarkAttention: "Att.:",
        MarkCare: "C/O:",
        MathematicOperatorCode: "Operator:",
        MaximumQuantity: "Maks bestilling:",
        MaximumBackorderQuantity: "Maks restordre:",
        MinimumQuantity: "Min bestilling:",
        MinimumBackorderQuantity: "Min restordre:",
        MinimumPurchase: "Minimumskøb:",
        ModelName: "Model:",
        MultiplierFactorNumeric: "Omregningsfaktor:",
        Name: "Navn:",
        Notes: "Yderligere oplysninger:",
        OIOUBLCat: "KATALOG",
        OIOUBLAR: "APPLIKATIONSRESPONS",
        OIOUBLCN: "KREDITNOTA",
        OIOUBLDoc: "OIOUBL dokumentparametre",
        OIOUBLInv: "FAKTURA",
        OIOUBLOrd: "ORDRE",
        OIOUBLOrdC: "ORDREANNULLERING",
        OIOUBLOrdRSim: "SIMPEL ORDRERESPONS",
        OrderableUnit: "Bestillingsenhed",
        OrderableUnitFactorRate: "Basis-/ordremængde faktor:",
        OrderCancellationID: "Ordreannullerings ID:",
        OrderDocumentReference: "Ordrereference:",
        OrderID: "Ordrenr:",
        OrderIssueDate: "Ordre dato",
        OrderLineReferenceID: "Ordrelinjeref:",
        OrderLineStatusCode: "Ordrelinjestatus:",
        OrderReferenceID: "Købers ordrenr:",
        OrderResponseID: "Ordrerespons ID:",
        OrderUUID: "Ordre UUID",
        OriginatorCustomerParty: "Oprindelig kunde",
        OriginAddress: "Oprindelsessted:",
        OriginCountry: "Oprindelsesland:",
        PackSizeNumeric: "Pakkestørrelse:",
        PackQuantity: "Pakkemængde:",
        PartialDeliveryIndicatorTrue: "Dellevering tilladt",
        PartyTaxScheme: "Moms",
        PartyLegalEntity: "Juridisk",
        PayableAmountInv: "Fakturatotal incl moms",
        PayableAmountCN: "Kreditnotatotal incl moms",
        PayableRoundingAmount: "Afrundingsbeløb:",
        PayeeParty: "Faktureringsadresse",
        PayeeFinancialAccountID: "Konto:",
        PayeeFinancialAccountIDNr: "Kontonr:",
        FinancialInstitutionBranchID: "Reg.nr:",
        PayeeFinancialAccount: "Sælgers kontooplysninger:",
        PayerFinancialAccount: "Købers kontooplysninger:",
        PaymentAlternativeExchangeRate: "Vekselkurs alternativ betaling:",
        PaymentCurrencyCode: "Betaling valuta:",
        PaymentAlternativeCurrencyCode: "Alternativ betaling valuta:",
        PaymentDueDate: "Sidste betalingsdato:",
        PaymentExchangeRate: "Vekselkurs betaling:",
        PaymentMeans: "Betalingsmåde",
        PaymentMeansID: "Betalingsmåde nr:",
        PaymentMeansCode42: "Indenlandsk kontooverførsel:",
        PaymentMeansCode30: "Udenlandsk kontooverførsel:",
        PaymentMeansCode50: "Indbetalingskort (GIRO):",
        PaymentMeansCode93: "Indbetalingskort (FIK):",
        PaymentMeansCode49: "Opkræves via betalingsservice:",
        PaymentMeansCode97: "Beløbet overføres via NemKonto",
        PaymentMeansCodeZZZ: "Betalingsmåden er aftalt mellem parterne",
        PaymentMeansCodeX: "Ufuldstændige betalingsoplysninger",
        PaymentNote: "Note til betalingsmodtager:",
        PaymentTerms: "Betalingsbetingelser",
        PaymentTermsID: "Type:",
        PenaltyPeriodStartDate: "Strafrentedato:",
        PenaltySurchargePercent: "Strafrentesats (procent):",
        Percent: "Procent:",
        PeriodStartDate: "Fra:",
        PeriodEndDate: "Til:",
        PeriodDescription: "Beskrivelse:",
        PerUnitAmount: "Beløb pr. enhed:",
        Postbox: "Postboks:",
        PrepaidAmount: "Forudbetat beløb:",
        PrepaidPayment: "Forudbetaling:",
        PrepaidPaymentID: "ID:",
        PrepaidPaidAmount: "Forudbetalt beløb:",
        PrepaidReceivedDate: "Forudbetaling modtaget:",
        PrepaidPaidDate: "Forudbetalingsdato:",
        PrepaidPaidTime: "Forudbetalingstid:",
        PrepaidInstructionID: "Instruktions ID:",
        PrepaidPaymentReferenceID: "Forudbetalingsreference:",
        PriceType: "Pristype:",
        PriceTypeCode: "Pristypekode:",
        PriceUnit: "Enhedspris",
        PriceValidityPeriode: "Pris gyldighedsperiode:",
        PricingCurrencyCode: "Pris valuta:",
        PricingExchangeRate: "Vekselkurs pris:",
        ProfileID: "Profil ID:",
        ProviderParty: "Udbyder",
        Quantity: "Antal",
        QuantityUnitCode: "Enhed",
        QuotationDocumentReference: "Tilbudsreference:",
        ReceiverParty: "Modtager",
        ReferenceEventCode: "Milepælkode:",
        ReferenceIDAR: "Reference Id",
        ReferenceID: "Fordringsforklaring:",
        RejectionNote: "Afvisningsårsag:",
        ReminderDocumentReference: "Rykkerreference:",
        RequestedDeliveryPeriod: "Ønsket levering:",
        RequestedInvoiceCurrencyCode: "Ønsket fakturavaluta:",
        ResponseCode: "Responskode",
        ResponseDescription: "Årsag",
        SalesOrderID: "Sælgers ordrenr:",
        SelfBilledInvoiceDocumentReference: "Selvfakturering reference",
        SellersItemIdentification: "Varenr",
        SellersItemIdentificationExt: "Udvidet varenr:",
        SellerParty: "Leverandør",
        SellerSupplierParty: "Sælger",
        SenderParty: "Afsender",
        SettlementDiscountPercent: "Kontantrabatsats (procent):",
        SettlementPeriodEndDate: "Kontantrabatdato:",
        SequenceNumeric: "Beregningssekvens:",
        SerialID: "Serienummer:",
        Signature: "Signatur",
        SignatureID: "ID:",
        SignatureNote: "Note:",
        SignatureValidationDate: "Valideringsdato:",
        SignatureValidationTime: "Valideringstid:",
        SignatureValidationID: "Validerings ID:",
        SignatureCanonicalizationMethod: "Kanoniseret metode:",
        SignatureMethod: "Signatur metode:",
        SignatureParty: "Signeret af:",
        SignatureAttachment: "Vedhæftet:",
        SignatureOriginalDocumentReference: "Originaldokument:",
        SourceCurrencyBaseRate: "Kilde valutakurs:",
        SourceCurrencyCode: "Kilde valutakode:",
        StandardItemIdentification: "Standard varenr:",
        StandardItemIdentificationExt: "Standard udvidetvarenr:",
        TargetCurrencyBaseRate: "Modtager valutakurs:",
        TargetCurrencyCode: "Modtager valutakode:",
        TaxAmount: "Afgiftsbeløb:",
        TaxAmountTotal: "Samlet afgiftsbeløb:",
        TaxableAmount: "Afgiftsgrundlag:",
        TaxCalculationSequenceNumeric: "Beregningsrækkefølge:",
        TaxCategoryID: "Afgiftskategori:",
        TaxCategoryID2: "Kategori:",
        TaxCategoryIDVAT: "Total momsbeløb",
        TaxCategoryIDNotVAT: "Afgift",
        TaxCurrencyCode: "Afgift valuta:",
        TaxExclusiveAmount: "Afgift total:",
        TaxExchangeRate: "Vekselkurs afgift:",
        TaxInclusiveAmount: "Afgift total incl moms:",
        TaxRoundingAmount: "Afrundingsbeløb:",
        TaxScheme: "Moms",
        TaxSchemeID: "Pligtkode:",
        TaxTotalSupp: "Supplerende oplysninger om skatter/afgifter",
        TaxTypeCode: "Momspligt:",
        TaxTypeCodeVAT: "Momspligtig",
        TaxTypeCodeZERORATED: "Ikke-momspligtig",
        TaxTypeCodeREVERSECHARGE: "REVERSECHARGE",
        TaxPercent: "%",
        TaxTypeCodeVATTotal: "Momsgrundlag",
        TaxTypeCodeZERORATEDTotal: "Momsfri andel",
        TaxTypeCodeZERORATEDTotal2: "Momsfri andel (REVERSECHARGE)",
        Telefax: "Fax:",
        Telephone: "Tlf:",
        TotalTaxAmount: "Afgift total:",
        TransactionCurrencyTaxAmount: "Transaktionsbeløb:",
        Type: "Type:",
        URI: "URI:",
        UUID: "UUID:",
        UniqueCatalogueItemID: "Katalog LinjeID:",
        ValidityPeriod: "Gyldighedsperiode:",
        VersionID: "UBLVersionID:",
        XPath: "XPath:",
      },
      /*
      terms: {
        Release: "OIOUBL visning version 1.5.0 (2013)",
        AcceptedIndicator: "Respons",
        AcceptedIndicatorFalse: "Afvist",
        AcceptedIndicatorTrue: "Accepteret",
        AccountingCost: "Dimensions konto:",
        AccountingCostCode: "Dimensions kontokode",
        AccountingCustomerInv: "Fakturamodtager",
        AccountingCustomerOrd: "Fakturamodtager",
        AccountingSupplierInv: "Fakturaafsender",
        AccountingSupplierOrd: "Fakturaudsteder",
        ActualDeliveryDate: "Leveringsdag:",
        ActualDeliveryTime: "Leveringstid",
        AdditionalDocumentReferenceID: "Yderligere reference:",
        AdditionalInformation: "Supplerende information:",
        AdditionalItemIdentification: "Yderligere varenr:",
        AdditionalItemIdentificationExt: "Yderligere udvidetvarenr:",
        AdditionalItemProperty: "Yderligere varebeskrivelse:",
        AddressAdditionalStreet: "Vej adresseringsnavn:",
        AddressID: "Adresse ID:",
        AddressBuildingName: "Lokaliet:",
        AddressCountry: "Land:",
        AddressDistrict: "Distrikt:",
        AddressRegion: "Region:",
        AddressRoom: "Rum:",
        AllowanceChargePrice: "Pris incl",
        AllowanceChargeSupp: "Supplerende oplysninger til Gebyr/Rabat",
        AllowanceTotalAmount: "Rabat total:",
        Amount: "Beløb:",
        AnticipatedMonetaryTotalOrd: "Forventet ordretotal incl moms",
        ApplicationResponseID: "Respons ID:",
        BackOrderAllowedIndicatorTrue: "Restordre tilladt",
        BaseAmount: "Basis beløb:",
        BaseUnitMeasure: "Basis enhed:",
        BillingReferenceLine: "Linjereference:",
        BrandName: "Mærke:",
        BuyerParty: "Køber",
        BuyersItemIdentification: "Købers varenr:",
        BuyersItemIdentificationExt: "Købers udvidetvarenr:",
        CalculationRate: "Beregningskurs:",
        CancellationNote: "Annulleringsårsag",
        CatalogueID: "KatalogID:",
        CatalogueIndicatorTrue: "Katalogvare",
        CatalogueItemIdentification: "Katalog varenr:",
        CatalogueItemIdentificationExt: "Katalog udvidetvarenr:",
        CatalogueIssueDate: "UdstedelsesDato:",
        ChargeIndicatorTrue: "Gebyr",
        ChargeIndicatorFalse: "Rabat",
        ChargeTotalAmount: "Gebyr total:",
        ClassifiedTaxCategory: "Klassificeret afgiftskategori:",
        CommodityCode: "Varetypekode:",
        Contact: "Kontaktoplysninger",
        ContactID: "ID:",
        ContentUnitQuantity: "Salgbar kvantum",
        ContractID: "Kontrakt ID:",
        ContractIssueDate: "Kontraktdato:",
        ContractDocumentReference: "Kontraktreference:",
        ContractDocumentReferenceID: "Kontrakt:",
        ContractType: "Kontrakttype:",
        ContractTypeCode: "Kontrakttypekode:",
        CopyIndicator: "(Kopi)",
        CopyIndicatorTrue: "Kopiindikator: Kopi",
        CopyIndicatorFalse: "Kopiindikator: Original",
        CreditNoteDocumentReference: "Kreditnotareference:",
        CreditNoteID: "Kreditnotanr:",
        CurrencyCode: "Valutakode:",
        CustomizationID: "CustomizationID:",
        Delivery: "Leveringsoplysninger",
        DeliveryAddress: "Leveringsadresse",
        DeliveryLocation: "Leveringssted:",
        DeliveryLocationAddress: "Adresse:",
        DeliveryLocationID: "ID:",
        DeliveryLocationDescription: "Beskrivelse:",
        DeliveryLocationConditions: "Betingelser:",
        DeliveryTerms: "Leveringsbeting.",
        DeliveryTermsID: "Leverings ID:",
        DeliverySpecialTerms: "Specielle leveringsbeting:",
        DeliveryTime: "Levering",
        DeliveryParty: "Leveringspart:",
        Description: "Beskrivelse:",
        DocumentCurrencyCode: "Dokument valuta:",
        DocumentHash: "Hash værdi:",
        DocumentReference: "Dokumentreference",
        DocumentReferenceID: "Dokument ID",
        DocumentType: "Dokumenttype:",
        DocumentTypeCode: "Dokumenttype kode:",
        ElectronicMail: "E-mail: ",
        EmbeddedDocumentBinaryObject: "Indlejret dokument:",
        EndpointID: "EndepunktID",
        EndpointSender: "Afsender EndepunktD:",
        EndpointReceiver: "Modtager EndepunktD:",
        ExchangeMarketID: "Valutamarked:",
        ExpiryDate: "Udløbsdato:",
        ExpiryTime: "Udløbstid:",
        ExtendedDescription: "Lang beskrivelse:",
        FinancialInstitutionID: "SWIFT:",
        FinancialInstitutionName: "Bank:",
        FreeOfChargeIndicatorTrue: "Varen er gratis",
        ID: "ID:",
        ItemClassificationCode: "Vareklassificering:",
        ItemDescription: "Varebeskrivelse:",
        InvoiceDocumentReference: "Fakturareference:",
        InvoiceID: "Fakturanr:",
        InvoiceIssueDate: "Fakturadato:",
        InvoicePeriod: "Fakturaperiode:",
        InspectionMethodCode: "Inspektionsmetodekode:",
        InstructionNote: "Lang advisering:",
        IssueDate: "Dato:",
        ItemName: "Beskrivelse",
        JurisdictionRegionAddress: "Myndighedsområde:",
        LatestDeliveryDate: "Senest leveringsdato:",
        LatestDeliveryTime: "Senest leveringstidspunkt:",
        LeadTimeMeasure: "Leveringstid:",
        LegalMonetaryTotal: "Supplerende oplysninger om totaler",
        LineExtensionAmountTotal: "Linjesum i alt excl moms",
        LineExtensionAmountLine: "Pris",
        LineID: "Linje",
        LineResponse: "Supplerende linjeoplysninger",
        LineReference: "Linjereference",
        LotNumberID: "Lotidentifikation:",
        ManufacturersItemIdentificationExt: "Producentens udvidetvarenr:",
        MarkAttention: "Att.:",
        MarkCare: "C/O",
        MathematicOperatorCode: "Operator:",
        MaximumQuantity: "Maks bestilling:",
        MaximumBackorderQuantity: "Maks restordre:",
        MinimumQuantity: "Min bestilling:",
        MinimumBackorderQuantity: "Min restordre:",
        MinimumPurchase: "Minimumskøb:",
        ModelName: "Model:",
        MultiplierFactorNumeric: "Omregningsfaktor:",
        Name: "Navn:",
        Notes: "Yderligere oplysninger:",
        OIOUBLCat: "KATALOG",
        OIOUBLAR: "APPLIKATIONSRESPONS",
        OIOUBLCN: "KREDITNOTA",
        OIOUBLDoc: "OIOUBL dokumentparametre",
        OIOUBLInv: "FAKTURA",
        OIOUBLOrd: "ORDRE",
        OIOUBLOrdC: "ORDREANNULLERING",
        OIOUBLOrdRSim: "SIMPEL ORDRERESPONS",
        OrderableUnit: "Bestillingsenhed",
        OrderableUnitFactorRate: "Basis-/ordremængde faktor:",
        OrderCancellationID: "Ordreannullerings ID:",
        OrderDocumentReference: "Ordrereference:",
        OrderID: "Ordrenr:",
        OrderIssueDate: "Ordre dato",
        OrderLineReferenceID: "Ordrelinjeref:",
        OrderLineStatusCode: "Ordrelinjestatus:",
        OrderReferenceID: "Købers ordrenr:",
        OrderResponseID: "Ordrerespons ID:",
        OrderUUID: "Ordre UUID",
        OriginatorCustomerParty: "Oprindelig kunde",
        OriginAddress: "Oprindelsessted:",
        OriginCountry: "Oprindelsesland:",
        PackSizeNumeric: "Pakkestørrelse:",
        PackQuantity: "Pakkemængde:",
        PartialDeliveryIndicatorTrue: "Dellevering tilladt",
        PartyTaxScheme: "Moms",
        PartyLegalEntity: "Juridisk",
        PayableAmountInv: "Fakturatotal incl moms",
        PayableAmountCN: "Kreditnotatotal incl moms",
        PayableRoundingAmount: "Afrundingsbeløb:",
        PayeeParty: "Faktureringsadresse",
        PayeeFinancialAccountID: "Konto:",
        PayeeFinancialAccountIDNr: "Kontonr:",
        FinancialInstitutionBranchID: "Reg.nr:",
        PayeeFinancialAccount: "Sælgers kontooplysninger:",
        PayerFinancialAccount: "Købers kontooplysninger:",
        PaymentAlternativeExchangeRate: "Vekselkurs alternativ betaling:",
        PaymentCurrencyCode: "Betaling valuta:",
        PaymentAlternativeCurrencyCode: "Alternativ betaling valuta:",
        PaymentDueDate: "Sidste betalingsdato:",
        PaymentExchangeRate: "Vekselkurs betaling:",
        PaymentMeans: "Betalingsmåde",
        PaymentMeansID: "Betalingsmåde nr:",
        PaymentMeansCode42: "Indenlandsk kontooverførsel:",
        PaymentMeansCode30: "Udenlandsk kontooverførsel:",
        PaymentMeansCode50: "Indbetalingskort (GIRO):",
        PaymentMeansCode93: "Indbetalingskort (FIK):",
        PaymentMeansCode49: "Opkræves via betalingsservice:",
        PaymentMeansCode97: "Beløbet overføres via NemKonto",
        PaymentMeansCodeZZZ: "Betalingsmåden er aftalt mellem parterne",
        PaymentMeansCodeX: "Ufuldstændige betalingsoplysninger",
        PaymentNote: "Note til betalingsmodtager:",
        PaymentTerms: "Betalingsbetingelser",
        PaymentTermsID: "Type:",
        PenaltyPeriodStartDate: "Strafrentedato:",
        PenaltySurchargePercent: "Strafrentesats (procent):",
        Percent: "Procent:",
        PeriodStartDate: "Fra:",
        PeriodEndDate: "Til:",
        PeriodDescription: "Beskrivelse:",
        PerUnitAmount: "Beløb pr. enhed:",
        Postbox: "Postboks",
        PrepaidAmount: "Forudbetat beløb:",
        PrepaidPayment: "Forudbetaling:",
        PrepaidPaymentID: "ID:",
        PrepaidPaidAmount: "Forudbetalt beløb:",
        PrepaidReceivedDate: "Forudbetaling modtaget:",
        PrepaidPaidDate: "Forudbetalingsdato:",
        PrepaidPaidTime: "Forudbetalingstid:",
        PrepaidInstructionID: "Instruktions ID:",
        PrepaidPaymentReferenceID: "Forudbetalingsreference:",
        PriceType: "Pristype:",
        PriceTypeCode: "Pristypekode:",
        PriceUnit: "Enhedspris",
        PriceValidityPeriode: "Pris gyldighedsperiode:",
        PricingCurrencyCode: "Pris valuta:",
        PricingExchangeRate: "Vekselkurs pris:",
        ProfileID: "Profil ID:",
        ProviderParty: "Udbyder",
        Quantity: "Antal",
        QuantityUnitCode: "Enhed",
        QuotationDocumentReference: "Tilbudsreference:",
        ReceiverParty: "Modtager",
        ReferenceEventCode: "Milepælkode:",
        ReferenceIDAR: "Reference Id",
        ReferenceID: "Fordringsforklaring:",
        RejectionNote: "Afvisningsårsag:",
        ReminderDocumentReference: "Rykkerreference:",
        RequestedDeliveryPeriod: "Ønsket levering:",
        RequestedInvoiceCurrencyCode: "Ønsket fakturavaluta:",
        ResponseCode: "Responskode",
        ResponseDescription: "Årsag",
        SalesOrderID: "Sælgers ordrenr:",
        SelfBilledInvoiceDocumentReference: "Selvfakturering reference",
        SellersItemIdentification: "Varenr",
        SellersItemIdentificationExt: "Udvidet varenr:",
        SellerParty: "Leverandør",
        SellerSupplierParty: "Sælger",
        SenderParty: "Afsender",
        SettlementDiscountPercent: "Kontantrabatsats (procent):",
        SettlementPeriodEndDate: "Kontantrabatdato:",
        SequenceNumeric: "Beregningssekvens:",
        Signature: "Signatur",
        SignatureID: "ID:",
        SignatureNote: "Note:",
        SignatureValidationDate: "Valideringsdato:",
        SignatureValidationTime: "Valideringstid:",
        SignatureValidationID: "Validerings ID:",
        SignatureCanonicalizationMethod: "Kanoniseret metode:",
        SignatureMethod: "Signatur metode:",
        SignatureParty: "Signeret af:",
        SignatureAttachment: "Vedhæftet:",
        SignatureOriginalDocumentReference: "Originaldokument:",
        SourceCurrencyBaseRate: "Kilde valutakurs:",
        SourceCurrencyCode: "Kilde valutakode:",
        StandardItemIdentification: "Standard varenr:",
        StandardItemIdentificationExt: "Standard udvidetvarenr:",
        TargetCurrencyBaseRate: "Modtager valutakurs:",
        TargetCurrencyCode: "Modtager valutakode:",
        TaxAmount: "Afgiftsbeløb:",
        TaxAmountTotal: "Samlet afgiftsbeløb:",
        TaxableAmount: "Afgiftsgrundlag:",
        TaxCalculationSequenceNumeric: "Beregningsrækkefølge:",
        TaxCategoryID: "Afgiftskategori:",
        TaxCategoryID2: "Kategori:",
        TaxCategoryIDVAT: "Total momsbeløb",
        TaxCategoryIDNotVAT: "Afgift",
        TaxCurrencyCode: "Afgift valuta:",
        TaxExclusiveAmount: "Afgift total:",
        TaxExchangeRate: "Vekselkurs afgift:",
        TaxInclusiveAmount: "Afgift total incl moms:",
        TaxRoundingAmount: "Afrundingsbeløb:",
        TaxScheme: "Moms",
        TaxSchemeID: "Pligtkode:",
        TaxTotalSupp: "Supplerende oplysninger om skatter/afgifter",
        TaxTypeCode: "Momspligt:",
        TaxTypeCodeVAT: "Momspligtig",
        TaxTypeCodeZERORATED: "Ikke-momspligtig",
        TaxTypeCodeREVERSECHARGE: "REVERSECHARGE",
        TaxTypeCodeVATPercent: "25%",
        TaxTypeCodeZERORATEDPercent: "0%",
        TaxTypeCodeVATTotal: "Momsgrundlag",
        TaxTypeCodeZERORATEDTotal: "Momsfri andel",
        TaxTypeCodeZERORATEDTotal2: "Momsfri andel (REVERSECHARGE)",
        Telefax: "Fax:",
        Telephone: "Tlf:",
        TotalTaxAmount: "Afgift total:",
        TransactionCurrencyTaxAmount: "Transaktionsbeløb:",
        URI: "URI:",
        UUID: "UUID:",
        UniqueCatalogueItemID: "Katalog LinjeID:",
        ValidityPeriod: "Gyldighedsperiode:",
        VersionID: "UBLVersionID:",
        XPath: "XPath:",
      },
      */
    };
  },
  created() {
    this.fetchPreview();
  },
  methods: {
    async fetchPreview() {
      const invoiceId = this.$route.params.id;
      const service = new ResourceService("economics/invoices");
      let xmlData = (await service.get(`${invoiceId}/source-file`)).data;
      let stylesheetPath = xmlData.includes("xsd:CreditNote")
        ? "/files/CreditNoteHTML.xsl"
        : "/files/InvoiceHTML.xsl";
      axios.get(stylesheetPath).then((xsltData) => {
        let xsltDataWithTerms = xsltData.data;
        for (let key in this.terms) {
          let replaceValue = this.terms[key];
          xsltDataWithTerms = xsltDataWithTerms.replace(
            `<xsl:value-of select="$moduleDoc/module/document-merge/g-funcs/g[@name='${key}']"/>`,
            replaceValue
          );
        }

        const xsltProcessor = new XSLTProcessor();
        const parser = new DOMParser();
        const xmlDoc = parser.parseFromString(xmlData, "application/xml");
        const xsltDoc = parser.parseFromString(
          xsltDataWithTerms,
          "application/xml"
        );

        xsltProcessor.importStylesheet(xsltDoc);
        const transformedDoc = xsltProcessor.transformToDocument(xmlDoc);
        const serializer = new XMLSerializer();
        this.transformedData = serializer.serializeToString(transformedDoc);
      });
    },
  },
};
</script>

<style scoped>
.invoice-wrapper {
  margin: 20px;
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
</style>